import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import metrics from '../metrics';
import { Box, Section, Stack, Tiles } from '@nib/layout';
import Heading from '@nib-components/heading';
import { StyledHeading } from '@nib-components/form';
import Card from '@nib/card';
import Link from '@nib-components/link';

const SitemapPage = ({ data }): JSX.Element => (
  <Layout>
    <Helmet>
      <title>Sitemap | nib</title>
      <meta name="description" content="Sitemap" />
    </Helmet>
    <Section>
      <Box display="flex" justifyContent="center" marginBottom={8}>
        <Heading component="h1" size={1} color="trueGreen">
          Sitemap
        </Heading>
      </Box>
      <Stack space={{ xs: 2, md: 3 }}>
        <Tiles space={{ xs: 4, md: 6 }} columns={{ xs: 1, md: 2, xl: 3 }} flex>
          {data.allContentfulSiteMap.nodes[0].siteMapJson.map((section) => (
            <Card align="left" padding={0} key={getSection(section).sectionTitle}>
              <Card.Content title="">
                <Box
                  paddingTop={2}
                  paddingHorizontal={{ xs: 2, lg: 4 }}
                  paddingBottom={{ xs: 2, lg: 4 }}
                  flexGrow={1}
                >
                  <Box display="flex" justifyContent="left" marginBottom={5}>
                    <StyledHeading size={2} component="h4" color="trueGreen">
                      {getSection(section).sectionTitle}
                    </StyledHeading>
                  </Box>
                  {getSection(section).pages.map((page) => (
                    <Box display="flex" justifyContent="left" marginBottom={2}>
                      <Link href={page.url} underline={true} key={page.url}>
                        {page.label}
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Card.Content>
            </Card>
          ))}
        </Tiles>
      </Stack>
    </Section>
  </Layout>
);

const getSection = (sectionData: any) => {
  return JSON.parse(sectionData.internal.content);
};

export const sitemapData = graphql`
  query {
    allContentfulSiteMap {
      nodes {
        siteMapJson {
          internal {
            content
          }
        }
      }
    }
  }
`;

export default metrics({ pageName: 'sitemap-page' })(SitemapPage);
